/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//import "typeface-quattrocento-sans"
//import "typeface-work-sans"

import "typeface-eb-garamond"
import "./src/css/estil.css"
